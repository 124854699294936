import React, {useState, useEffect} from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import * as Styles from './bird.module.css'
import { GatsbyImage } from "gatsby-plugin-image"

const Bird = (props, data) => {

    const birdImages = useStaticQuery(graphql`
    query birdImages {
        images: allFile {
          edges {
            node {
              absolutePath
              name
              childImageSharp {
                gatsbyImageData(width: 2500)
              }
            }
          }
        }
    }
  `);

    
    const [active, setActive] = useState(false)

    const image = birdImages.images.edges.filter(n => {
        return n.node.absolutePath.includes('/images/birds/' + props.data.name.replace(/\s+/g, '-').toLowerCase())
    });

    const [currentImage, setCurrentImage] = useState(0)

    const imageNext = () => {
        if(currentImage < image.length - 1) {
            setCurrentImage(currentImage + 1)
        }
    }

    const imagePrev = () => {
        if(currentImage > 0) {
            setCurrentImage(currentImage - 1)
        }
    }

    useEffect(() => {
        if(props.open) {
            setActive(true)
        } else if(!props) {
            setActive(false)
        }
    })

    
    return (
        <div className={Styles.bird}> 
            <div className={Styles.birdNameWrapper}>
                <button onClick={() => setActive(!active)}>
                    <h3 
                    className={Styles.birdName}
                    style={{color: '#' + props.color}}>
                        {props.data.name}
                    </h3>
                </button>
            </div>
            <div className={`${Styles.birdContainer} ${active ? Styles.birdActive : ''}`}>
                <div className={Styles.photosTransition}>
                    <div className={Styles.photos} style={{
                            width: 'calc(' + image.length * 100 + '%)',
                            transform: 'translateX(calc(' + (0 - ((100 / image.length) * currentImage)) + '%' + '))'
                        }}>
                        {  image.length > 0 &&
                             image.slice().reverse().map((photo, i) => {
                                return <GatsbyImage 
                                    key={photo.node.absolutePath}
                                    image={photo.node.childImageSharp.gatsbyImageData}
                                    alt="vl"
                                    className={`${Styles.photo} ${currentImage === i ? Styles.photoActive : ''}`}
                                    // height="1695"
                                    layout="constrained"
                                    objectFit="contain"
                                    objectPosition="0 0"
                                />
                            })
                        }
                    </div> 
                    <div className={Styles.content}>
                        <header className={Styles.contentHeader}>
                            <div className={Styles.contentTitle}>
                                <div>{props.data.name}</div>
                                <div className={Styles.status}>{props.data.status}</div>
                            </div>
                            { props.data.photos.length > 1 &&
                                <nav className={Styles.nav}>
                                    <span className={currentImage === 0 ? Styles.muted : ''} onClick={() => imagePrev()}>previous image</span>
                                    <span className={currentImage === props.data.photos.length - 1 ? Styles.muted : ''} onClick={() => imageNext()}>next image</span>
                                </nav>
                            }
                        </header>
                        <div className={Styles.description}>
                            <p><span className={Styles.muted}>Details:</span> {props.data.description}</p>
                        </div>
                        <div className={Styles.notes} >
                            { props.data.photos[currentImage].comment &&
                                <p><span className={Styles.muted}>Notes:</span> {props.data.photos[currentImage].comment}</p>
                            }
                        </div>
                        <div className={Styles.table}>
                            <div className={`${Styles.tr} ${Styles.col1}`}>
                                <div className={Styles.th}>date</div>
                                <div className={Styles.td}>{props.data.photos[currentImage].date}</div>
                            </div>
                            <div className={`${Styles.tr} ${Styles.col2}`}>
                            <div className={Styles.th}>shutter speed</div>
                                <div className={Styles.td}>{props.data.photos[currentImage].shutter}</div>
                            </div>
                            <div className={`${Styles.tr} ${Styles.col1}`}>
                                <div className={Styles.th}>time</div>
                                <div className={Styles.td}>{props.data.photos[currentImage].time}</div>
                            </div>
                            <div className={`${Styles.tr} ${Styles.col2}`}>
                                <div className={Styles.th}>iso</div>
                                <div className={Styles.td}>{props.data.photos[currentImage].iso}</div>
                            </div>
                            <div className={`${Styles.tr} ${Styles.col1}`}>
                                <div className={Styles.th}>location</div>
                                <div className={Styles.td}>{props.data.photos[currentImage].location}</div>
                            </div>
                            <div className={`${Styles.tr} ${Styles.col2}`}>
                                <div className={Styles.th}>focal length</div>
                                <div className={Styles.td}>{props.data.photos[currentImage].focal}mm</div>
                            </div>
                            <div className={`${Styles.tr} ${Styles.col1}`}>
                                <div className={Styles.th}>camera</div>
                                <div className={Styles.td}>{props.data.photos[currentImage].camera}</div>
                            </div>
                            <div className={`${Styles.tr} ${Styles.col2}`}>
                                <div className={Styles.th}>aperture</div>
                                <div className={Styles.td}>f{props.data.photos[currentImage].aperture}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Bird
