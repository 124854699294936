import React, { useState, useEffect, useLayoutEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import Rainbow from "rainbowvis.js";
import Hero from "../../components/hero";
import Container from "../../components/container";
import Layout from "../../components/layout";
import Main from "../../components/main";
import Bird from "../../components/thebigyear/bird";
import * as Styles from "./index.module.css";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const TheBigYear = ({ data }) => {
  let allBirds = data.allBirdsJson.edges;
  const sortedBirds = allBirds.sort((a, b) => b.node.updated - a.node.updated);
  const [birds, setBirds] = useState(sortedBirds);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("date");
  const [toggleAllBirds, setToggleAllBirds] = useState(false);

  let rainbow = new Rainbow();
  let amount = 100 / allBirds.length;
  rainbow.setSpectrum(
    "#bdb76b",
    "#ac3636",
    "#aa80bb",
    "#384183",
    "#20DB98",
    "#B86868",
    "#A8B868"
  );

  let gradient = [];
  allBirds.forEach((bird, i) => {
    gradient.push(rainbow.colourAt(amount * i));
  });

  function sortByDate() {
    setFilter("date");
    const newOrder = [...birds].sort((a, b) => b.node.updated - a.node.updated);
    setBirds(newOrder);
  }

  function sortAlphabetically() {
    setFilter("alphabetically");
    const newOrder = [...birds].sort((a, b) =>
      a.node.name.localeCompare(b.node.name)
    );
    setBirds(newOrder);
  }

  function getLastImage() {
    const last = Math.max.apply(
      Math,
      birds.map((val) => {
        return val.node.updated;
      })
    );
    const date = new Date(last);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const year = date.getFullYear().toString().substr(-2);
    return day + "." + month + "." + year;
  }

  return (
    <Layout color={"dark"} hasFooter={true}>
      <Helmet>
        <title>The Big Year</title>
        <meta
          name="description"
          content="Photographing different species of birds around the world in a calendar year. 2021 photographed 191 birds in Australia, England, Wales and Sweden. View them all here."
        />
      </Helmet>
      <Hero
        heading="The Big Year is a collection of 191 species of bird all photographed in 2021."
        bgColor="darkkhaki"
      />
      <Main color={"dark"}>
        <Container>
          <div className={Styles.years}>
            <div className={`${Styles.year} ${Styles.yearActive}`}>2021</div>
            <div className={Styles.yearDivider}></div>
          </div>
          <div className={Styles.yearDetails}>
            <div className={Styles.yearDetail}>{birds.length} Birds</div>
            <div className={Styles.yearDetail}>
              Last bird on {getLastImage()}
            </div>
          </div>
          <div className={Styles.filters}>
            <div className={Styles.filterHeading}>Order</div>
            <button
              onClick={() => sortByDate()}
              className={filter === "date" ? Styles.filterActive : ""}
            >
              Date
            </button>
            <button
              onClick={() => sortAlphabetically()}
              className={filter === "alphabetically" ? Styles.filterActive : ""}
            >
              Alphabetical
            </button>
            {/* <StaticImage src="../../images/loader.svg" alt="loading icon" className={`${Styles.loader} ${loading ? Styles.loaderActive : ''}`}/> */}
            <button
              onClick={() => setToggleAllBirds(!toggleAllBirds)}
              className={`${Styles.filterAllBirds}`}
            >
              {!toggleAllBirds && <span>Open all birds</span>}
              {toggleAllBirds && <span>Close all birds</span>}
            </button>
          </div>
          <div className={Styles.birds}>
            {birds.map((bird, i) => {
              return (
                <Bird
                  open={toggleAllBirds}
                  data={bird.node}
                  color={gradient[i]}
                  key={bird.node.name.replace(/\s+/g, "-").toLowerCase()}
                />
              );
            })}
          </div>
        </Container>
      </Main>
    </Layout>
  );
};

export default TheBigYear;

export const query = graphql`
  query birdsQuery {
    allBirdsJson {
      edges {
        node {
          name
          description
          updated
          status
          photos {
            camera
            iso
            focal
            comment
            location
            shutter
            date
            time
            aperture
          }
        }
      }
    }
  }
`;
