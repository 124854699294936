// extracted by mini-css-extract-plugin
export var birds = "index-module--birds--e7559";
export var filterActive = "index-module--filter-active--b4ef0";
export var filterAllBirds = "index-module--filter-all-birds--db818";
export var filterHeading = "index-module--filter-heading--73af6";
export var filters = "index-module--filters--b4106";
export var loader = "index-module--loader--0f65e";
export var loaderActive = "index-module--loader-active--539df";
export var year = "index-module--year--d4c78";
export var yearActive = "index-module--year-active--a259f";
export var yearDetail = "index-module--year-detail--9b0d0";
export var yearDetails = "index-module--year-details--ec149";
export var yearDivider = "index-module--year-divider--1b9d4";
export var years = "index-module--years--e25a2";