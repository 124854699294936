import React, {useEffect} from 'react'
import * as Styles from './hero.module.css'
import { GatsbyImage } from "gatsby-plugin-image"
import Header from './header'
import Container from './container'

const Hero = (props) => {

    const hero = React.createRef()

    useEffect(() => {
        const windowHeight = window.innerHeight + "px"
        hero.current.style.height = windowHeight
    })

    return (
        <section className={Styles.heroWrapper} ref={hero} style={{backgroundColor: props.bgColor}}>
            <div className={Styles.hero}>
                <Header light={true} />

                { props.imageSrc &&
                    <GatsbyImage 
                    image={props.imageSrc}
                    alt={props.alt} 
                    width="628"
                    height="463" />
                }
                
                <div className={Styles.content}>
                    <Container>
                        <h1 className={Styles.heading}>
                            {props.heading}
                        </h1>
                        <svg className={Styles.arrow} width="136" height="156" viewBox="0 0 136 156" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M73 139.929V0H63V139.929L8.00002 84.9289L0.928955 92L64.4645 155.536H71.5356L135.071 92L128 84.9289L73 139.929Z" fill="white"/>
                        </svg>
                    </Container>
                </div>

            </div>
        </section>
    )
}

export default Hero